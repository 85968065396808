/* LOGIN------------------------------------------------------------ */

.login-container {
  padding: 90px 5% 0 5%;
  box-sizing: border-box;
  gap: 10%;
  justify-content: center;
}

.login-container > div {
  padding: 0 0 0 90px;
  display: flex;
  max-width: 40%;
  flex-direction: column;
  width: 40%;
  align-items: center;
  justify-content: center;
  gap: 20px;
  box-shadow: 11px 0px 10px -13px rgb(0 0 0 / 10%) inset;
}

.login-container .logo-container {
  width: 180px;
}

.login-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.login-container form .form-group {
  margin: 0;
  padding: 0;
}

.login-container form .form-group input {
  width: 210px;
  padding-left: 35px;
}

.forgot-password-img-container img {
  max-width: 240px;
}

/* Animación boton de usuario--------------------------------------------------------- */

.user-btn {
  white-space: nowrap;
  transition: all ease 0.2s;
}

.user-btn.is-showing-options {
  height: max-content;
  padding: 7px 10px;
  transition: all ease 0.5s;
}

.user-effect-container.is-visible:after {
  content: "";
  position: absolute;
  top: 15%;
  right: -70px;
  width: 120px;
  height: 17px;
  animation: whip 1.35s 0s;
  animation-iteration-count: 1;
}

.user-effect-container.is-visible:before {
  content: "";
  position: absolute;
  top: 15%;
  right: 70px;
  width: 120px;
  height: 17px;
  animation: whip 1.35s 0s;
  animation-iteration-count: 1;
}

.user-effect-container {
  position: absolute;
  top: 103%;
  right: 30%;
  height: 40px;
  opacity: 0;
  visibility: hidden;
  width: 90px;
  transition: all 0.5s ease;
  transform: translateY(-7px);
  z-index: 11;
  overflow: hidden;
}

.user-options {
  padding: 18px;
  display: flex;
  position: absolute;
  top: 103%;
  opacity: 0;
  visibility: hidden;
  right: 0%;
  flex-direction: column;
  z-index: 10;
  transition: visibility ease 3s;
  transition: opacity ease 3s;
  transition: transform ease 2s;
  min-width: 150px;
  transform: translateY(-10px);
}

.user-effect-container.is-visible .buble-1 {
  width: 5px;
  height: 5px;
  position: relative;
  top: 0%;
  animation: bubble-falling 4s 0.5s 1;
  animation-fill-mode: forwards;
}

.user-effect-container.is-visible .buble-2 {
  width: 7px;
  height: 7px;
  position: relative;
  top: 3%;
  left: 5px;
  animation: bubble-falling 4s 0.5s 1;
  animation-fill-mode: forwards;
}

.user-effect-container.is-visible .buble-3 {
  width: 7px;
  height: 7px;
  position: relative;
  top: -25%;
  left: 50%;
  animation: bubble-falling 4s 0.5s 1;
  animation-fill-mode: forwards;
}

.user-options.is-visible {
  animation: second 1.6s 0s;
  transform: translateY(12px);
  animation-iteration-count: 1;
  display: flex;
  min-width: 150px;
  flex-direction: column;
  gap: 18px;
}

@keyframes whip {
  0% {
    transform: scaleY(2);
  }
  50% {
    transform: scaleY(2.5);
  }
  75% {
    transform: scaleY(3);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes second {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(25px);
  }
  100% {
    transform: translateY(12px);
  }
}

@keyframes bubble-falling {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(30px);
  }
}

/* Botones ------------------------------------------------------------------------------------ */
.btn {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  gap: 7px;
  width: fit-content;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-action {
  justify-content: center;
  padding: 6px;
}

.btn-primary {
  padding: 7px 25px;
}

.btn-primary:hover {
  transform: scale(1.03);
}

.btn-alternative,
.btn-secondary {
  padding: 10px 15px;
}

.btn-secondary {
  text-align: center;
  font-size: 14px;
  position: relative;
}

.btn-secondary .Material-Icons-Outlined {
  font-size: 15px;
  position: absolute;
  top: 30%;
  right: 5%;
}

.simple-btn {
  padding: 10px 0;
}

.more-content {
  width: fit-content;
  position: absolute;
  right: 5%;
}

.btn-carousel-right,
.btn-carousel-left {
  position: absolute;
  display: block;
  bottom: 80px;
  height: 50px;
  width: 50px;
  z-index: 1;
}

.btn-carousel-right {
  right: -25px;
}

.btn-carousel-left {
  left: -25px;
}

.btn-toolbar {
  width: 100%;
}

.btn-guid-view {
  font-size: 20px;
  width: 100%;
  margin-top: 20px;
  justify-content: center;
}

/* GRAFICOS--------------------------------------------------------------------------- */
.donut-chart-box {
  width: 25vw;
  height: 20vw;
}

.bar-chart-box {
  width: 35vw;
  padding-bottom: 10px;
  padding-right: 10%;
}

.pie-chart-box {
  width: 17vw;
  height: 12vw;
  padding-bottom: 30px;
}

.line-chart-box {
  padding: 20px;
}

.chart-options {
  position: relative;
  left: 65%;
  bottom: 25px;
  height: 35px;
  border: none;
  padding: 5px;
}

.progress-bar-container {
  width: 100%;
  margin: 7px 0 20px 0;
}

.progress-bar {
  width: 50%;
  height: 10px;
}

/* Contenedores-------------------------------------------------------------------------- */

.container-blue-rounded {
  width: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;
}

.container-col-60 {
  width: 60%;
}

.container-col-30 {
  width: 30%;
}

.container-col-40 {
  width: 40%;
}

.container-col-50 {
  width: 50%;
}

.container-col-75 {
  max-width: 75%;
}

.card-img-container {
  width: 35%;
  padding: 10px;
}

.card-app-img-container {
  width: 45px;
  padding: 10px;
}

.form-container {
  margin-left: 250px;
}

.flex-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.card {
  position: relative;
  border: none;
  height: fit-content;
  text-align: left;
  margin-bottom: 50px;
  padding: 30px 25px;
  transition: all 0.5s ease;
}

.card.guid-view-card {
  max-width: 700px;
  margin: auto;
}

.card-transparent {
  padding: 30px 10px;
  transition: all 2s ease;
}

.card-simple-grey {
  margin: 20px 0;
  padding: 8px;
  position: relative;
}

.circular-container-sm {
  width: 37px;
  height: 37px;
  border-radius: 100%;
}

.context-actions-container {
  justify-content: flex-end;
  padding-right: 54px;
  position: fixed;
  right: 0;
  top: 78px;
  z-index: 3;
}

.container-col-whole-space {
  width: 100%;
  max-width: 100%;
}

.carousel-container {
  position: relative;
}

.carousel {
  display: flex;
  overflow: hidden;
  max-width: 100%;
  min-height: 155px;
  text-align: center;
}

.carousel-group {
  position: absolute;
  transition: all 1s ease;
  justify-content: center;
}

.editor-card-loading {
  min-height: 650px;
}

.editor-card-loading .skeleton-picture {
  width: 90px;
  height: 80px;
}

/* FORMULARIO------------------------------------------------------------------------------- */

.form-card {
  padding: 40px;
  display: flex;
  overflow: hidden;
  min-height: 60vh;
  position: relative;
}

.step {
  padding: 10px 15% 10px 0;
  text-align: left;
  position: relative;
}

.form-title {
  margin-top: 40px;
  text-align: left;
  position: relative;
}

.form-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.form-group {
  display: block;
  width: 210px;
  max-width: 100%;
  padding-top: 22px;
  margin-right: 40px;
  text-align: left;
}

.form-group.form-group-long {
  display: block;
  width: 470px;
  padding-top: 25px;
  margin-right: 40px;
  text-align: left;
}

.form-group-large {
  width: 470px;
}

.form-group input:not([type="checkbox"], [type="radio"]),
.form-group select,
.form-group textarea {
  height: 32px;
  width: 100%;
  padding: 5px 30px 5px 10px;
  box-sizing: border-box;
  margin-top: 5px;
  margin-bottom: 5px;
}

.form-group div {
  position: relative;
}

.input-symbol:not(.required-symbol) {
  left: 7px;
}

.input-symbol,
.red-icon {
  position: absolute;
  bottom: 26%;
}

.is-required .input-symbol.required-symbol,
.is-required .required-symbol.red-icon {
  right: 3%;
  bottom: 27%;
}

.is-required.select-required .input-symbol.required-symbol,
is-required.select-required .required-symbol.red-icon {
  right: 9%;
  bottom: 27%;
}

.photo-load-container {
  position: absolute;
  right: 40px;
}

.file-input-container {
  position: relative;
  display: flex;
  height: 35px;
  max-width: 225px;
  vertical-align: middle;
  justify-content: center;
}

.file-input-name {
  position: relative;
  right: 6px;
  border-radius: 7px;
  padding: 3px 10px;
  cursor: pointer;
}

.file-input-name:focus {
  outline: none;
}

.btn-file {
  background: var(--color-default);
  padding: 5px 7px;
  z-index: 2;
}

.form-group-photo {
  width: 55px;
  min-width: 55px;
  height: 55px;
  position: relative;
  overflow: hidden;
}

.form-group-photo img,
.form-group-photo .btn-load-img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: auto;
}

.form-group-photo .btn-load-img {
  width: 100%;
  height: 100%;
  border: none;
  text-align: center;
  cursor: pointer;
}

.form-group-photo input[type="file"] {
  opacity: 0;
  cursor: none;
  height: 0.5px;
}

.form-btn-group {
  position: absolute;
  bottom: 35px;
  display: flex;
  gap: 20px;
  z-index: 1;
  max-width: 285px;
}

.form-btn-group img {
  position: relative;
  right: 15px;
  min-width: 45px;
  margin: 0;
}

.form-toast {
  position: fixed;
  padding: 12px 15px;
  width: 20%;
  min-width: 190px;
  max-width: 20%;
  bottom: 80px;
  right: 50px;
  display: flex;
  gap: 17px;
  opacity: 0;
  visibility: hidden;
  z-index: 10;
  align-items: center;
  transform: translateX(50px);
}

.error-page {
  max-height: 100vh;
  min-height: 100vh;
  padding: 7%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.error-page img {
  max-width: 40%;
  margin-bottom: 15px;
}

.truncate-text {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-results-container {
  width: 100%;
  padding: 4% 20%;
  box-sizing: border-box;
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 20px;
}

.no-results-container img {
  width: 50%;
}

.array-btn-delete {
  position: relative;
  bottom: 18px;
}

.fake-switch {
  position: relative;
  display: inline-block;
  width: fit-content;
  height: 18px;
  padding: 0px 3px 2px 8px;
}

.fake-switch .material-icons {
  margin-left: 3px;
}

.fake-switch:after {
  content: "";
  position: absolute;
  width: 40%;
  height: 15px;
  top: 3px;
  left: 10%;
  transition: all 0.5s;
}

.fake-switch span {
  margin-right: 3px;
}

.checkbox-switch:checked + .fake-switch::after {
  left: 50%;
}

/* Checkbox vanished */
.checkbox-switch {
  display: none;
}

.switch-container {
  width: fit-content;
  display: block;
  margin-right: 40px;
  text-align: left;
}

.form-group .writable-select-input[type="number"] {
  position: absolute;
  max-width: 75%;
  border-radius: 1px;
  padding: 5px;
}

.range-container .input[type="number"] {
  width: 40px;
  padding: 4px 5px;
  border: 1px solid rgb(182, 180, 180);
  border-radius: 3px;
}

.input-transparent {
  background: transparent;
  border: none;
}

.number-input {
  width: 40px;
  padding: 5px;
  border-radius: 5px;
}

.number-input-unit {
  position: relative;
  left: -25px;
}

/* GENERADOR DE CERTIFICADO--------------------------------------------------- */
.root-project .project-sub-principal-container {
  position: relative;
  transition: all 2s ease;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.root-project .project-sub-principal-container > div {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  max-height: 50vh;
  scroll-behavior: smooth;
}

.generator {
  justify-content: center;
  align-items: flex-start;
}

.root-project .editor-container {
  width: 67%;
  justify-content: end;
  overflow: visible;
  margin: 12px auto;
  justify-content: center;
  position: static;
}

.project-bottom-bar {
  justify-content: end;
  padding: 5px 60px 35px 90px;
}

.project-bottom-bar .btn-alternative {
  background-color: var(--color-bg-variant);
}

.project-bottom-bar .btn-primary {
  height: 40px;
}

.project-bottom-bar img {
  width: 10%;
}

.generator-main-container {
  width: 100%;
  text-align: left;
}

.generator-options-container {
  background: var(--color-menu-palette);
  border-radius: 20px;
  padding: 15px 5px 15px 33px;
  margin-bottom: 20px;
}

.generator-inputs-container {
  align-self: flex-start;
  background: var(--color-menu-palette);
  padding: 20px;
  box-sizing: border-box;
  border-radius: 20px;
  margin: 0 30px 0 0;
  min-width: 70%;
}

.project-info-container {
  max-width: 80%;
}

.generator-inputs-container .form-inputs-container {
  display: flex;
  flex-wrap: wrap;
}

.generator-inputs-container .form-group {
  padding-top: 10px;
  position: relative;
  min-width: 50%;
  margin-right: 20px;
}

.generator-inputs-container textarea {
  width: 90%;
  height: 50px;
  margin-top: 5px;
}

.input-project-pagination {
  width: 31px;
  border-radius: 5px;
  height: 25px;
  text-align: center;
  margin: 5px;
}

/* EDITIONPAGE------------------------------------------------------------------ */

.input-project-name {
  background: transparent;
  outline: none;
  border: 1px solid var(--color-gray);
  padding: 5px;
  border-radius: 7px;
}

.editor-container {
  position: relative;
  display: flex;
  width: 67%;
  justify-content: center;
  overflow: visible;
  border-radius: 10px;
  left: 25%;
  top: 12%;
  margin: 90px 0 90px 60px;
}

.editor-data-settings-container {
  margin-left: auto;
  position: relative;
  padding: 20px 20px 75px 35px;
  text-align: left;
  min-height: 30%;
  height: 30%;
  overflow: hidden;
}

.editor-data-settings {
  min-width: 700px;
  min-height: 100%;
  max-height: 60%;
  overflow-y: auto;
}

div::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

div::-webkit-scrollbar-thumb {
  background-color: #626262;
  border-radius: 10px;
}

div::-webkit-scrollbar {
  width: 7px;
}

.editor-container:focus {
  outline: none;
}

.vertical-menu .menu-canvas.is-selected {
  width: 116%;
}

.sub-menu-container.palette {
  width: 310px;
  min-width: 310px;
  max-width: 310px;
  overflow: auto;
  padding: 20px 0;
  box-sizing: border-box;
}

.sub-menu-container.palette .qr-config-container {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  gap: 10px;
}

.settings-content .sub-menu-container.palette {
  max-height: 390px;
}

.sub-menu-container.palette ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.sub-menu-container.palette .registers-input-search {
  margin-bottom: 10px;
}

.sub-menu-container.palette .btn-secondary,
.sub-menu-container.palette .form-group {
  width: 70%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}

.sub-menu-container.palette .btn-secondary {
  padding-right: 30px;
}

.sub-menu-container.palette .btn-action {
  position: absolute;
  bottom: 11%;
  background: var(--color-bg);
  padding: 20px 0;
  width: 100%;
  border-radius: 0;
  color: var(--color-text);
}

.sub-menu-top-inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vertical-menu .sub-menu-container.palette ul li {
  width: 100%;
}

.sub-menu-container.palette button img {
  max-height: 120px;
  padding: 10px;
}

.palette::-webkit-scrollbar-track {
  background-color: var(--color-menu);
  border-radius: 10px;
}

.palette::-webkit-scrollbar-thumb {
  background-color: #7e7e7e;
  border-radius: 10px;
}

.palette::-webkit-scrollbar {
  width: 10px;
}

.edition-bottom-bar {
  position: fixed;
  bottom: 0;
  gap: 20px;
  width: 100%;
  height: 50px;
  background: var(--color-menu);
  display: flex;
  justify-content: right;
  padding: 12px 35px;
  box-sizing: border-box;
  align-items: center;
}

.edition-bottom-bar img {
  height: 50px;
}

.edition-top-bar {
  position: fixed;
  top: 70px;
  right: 0;
  background: var(--color-overlay);
  width: 100%;
  padding: 0 35px;
  box-sizing: border-box;
  z-index: 3;
  justify-content: right;
  min-height: 82px;
}

.edition-top-bar .tools-container {
  max-width: 68%;
  overflow-x: auto;
}

.edition-top-bar .form-group {
  margin: 0;
  width: auto;
  min-width: 70px;
}

.edition-top-bar input,
.edition-top-bar select {
  max-width: 165px;
  padding-right: 0;
}
.color-picker {
  background: none;
  height: 30px;
  width: 32px;
  border: none;
}

.edition-fontsize-container {
  width: 85px;
}

.edition-page-count {
  font-size: 13px;
}

.btn-bar-edition {
  background: var(--color-bg-input);
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
}

/* HOMEPAGE--------------------------------------------------------------------------------------- */
.home-cover {
  gap: 20%;
  padding: 30px 40px 30px 135px;
  height: 200px;
  font-size: large;
}

.home-cover img {
  position: absolute;
  right: 90px;
  top: 15%;
  height: 70%;
  width: auto;
}

.home-cover .cover-backing {
  position: absolute;
  width: 110px;
  height: auto;
  bottom: -21px;
  top: auto;
  left: -3px;
}

.home-page .mosaic-card {
  width: 210px;
}

.home-page .carousel-group {
  width: fit-content;
  max-width: fit-content;
}

.home-page .card-transparent {
  background-color: transparent;
}

.home-page .home-carousel-link {
  position: absolute;
  right: 90px;
  z-index: 1;
}

.home-page .home-btn-start {
  margin: 20px auto;
}

/* SECCION DE TABLAS -------------------------------------------------------------------- */

#main.register {
  padding: 0 50px;
}

.section-name {
  padding-left: 20px;
  text-align: left;
}

.table-container {
  min-width: 100%;
  min-height: 190px;
  position: relative;
  /* overflow: auto; */
}

.table {
  width: 100%;
  max-width: 100%;
  padding: 25px 12px;
}

.table td {
  padding: 15px 10px 15px 15px;
  position: relative;
  box-sizing: border-box;
  color: white;
}

.table tr th {
  padding: 15px 10px 15px 20px;
}

.table img:not(.img-no-results) {
  max-width: 15%;
}

.registers-btn-action {
  display: flex;
  align-items: center;
  padding: 10.5px 12px;
  border: none;
  white-space: nowrap;
  gap: 10px;
}

.registers-input-search {
  padding: 12.5px;
  border: none;
}

.btn-template {
  background-color: var(--color-default);
  border: none;
  display: flex;
  cursor: pointer;
  margin: auto;
}

.dataTable-bottom {
  position: relative;
  padding: 0 20px;
}

.dataTable-bottom .btn {
  padding: 5px 10px;
}

.dataTable-dropdown {
  border: none;
  margin: 0 10px;
}

.dataTable-pagination {
  position: absolute;
  right: 2%;
}

.pagination-numbers-container {
  max-width: 220px;
  overflow: hidden;
  padding-left: 70px;
}

.pagination-numbers-scroll {
  display: flex;
  position: relative;
}

.pagination-numbers-scroll .btn {
  min-width: 35px;
  max-width: 35px;
  justify-content: center;
}

.register-options-container {
  position: absolute;
  right: 60%;
  top: 20%;
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  cursor: none;
}

.view-options-container {
  position: absolute;
  top: 110%;
}

.register-options-container button,
.view-options-container button {
  padding: 15px 30px 15px 10px;
}

.filters-container {
  position: absolute;
  top: 111%;
  left: 0;
  padding: 0px;
  z-index: 7;
  max-height: 315px;
  overflow: scroll;
}

.filters-header {
  padding: 10px 20px;
}

.filters-container .filters-list {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-top: 20px;
  padding: 22px;
}

.filters-container .filters-list button {
  position: relative;
  width: 100%;
  padding-right: 35px;
}

.material-icons.active-filter-icon {
  position: absolute;
  right: 0;
}

/* MOSAICOS------------------------------------------------------------------------------- */

.mosaic-group {
  padding: 30px 20px;
  flex-wrap: wrap;
  gap: 1.4%;
}

.mosaic-card {
  width: 98%;
  position: relative;
  margin-bottom: 30px;
}

.mosaic-img-container {
  height: 125px;
  padding: 20px;
  background-color: var(--color-800);
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.register .mosaic-img-container img {
  min-width: 199px;
}

.mosaic-img-container img {
  height: 100%;
}

.mosaic-info {
  padding: 10px;
}

.mosaic-info .options {
  position: absolute;
  right: 5px;
}

/* MODAL------------------------------------------------------------------------------ */

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submenu-overlay {
  transition: all ease 0.5s;
  position: absolute;
  width: 100vw;
  height: 100vw;
  z-index: -10;
  left: 15px;
  display: flex;
  align-items: center;
  background: var(--color-bg-variant);
}

.modal {
  width: fit-content;
  height: fit-content;
  position: relative;
  transition: all ease 0.7s;
  max-height: 72%;
  max-width: 68%;
  overflow: hidden;
  transition: width 0.5s ease;
}

.modal-header {
  padding-left: 0;
  margin: 0;
  padding-bottom: 10px;
}

.modal-header .modal-title {
  margin: 0;
}

.modal-body {
  overflow-y: auto;
  max-height: 360px;
  padding: 7px;
}

.modal-bottom {
  height: fit-content;
  width: 100%;
  position: relative;
  right: 31px;
  padding: 25px;
  justify-content: right;
  display: flex;
}

.modal-delete .modal-bottom {
  right: 15px;
  padding: 10px;
}
.bottom-on-delete {
  width: 100%;
  display: flex;
  justify-content: center;
}

.modal .warning-icon {
  height: 150px;
  width: auto;
}

.modal-bottom .form-btn-group {
  position: static;
}

.modal-info {
  text-align: center;
  padding-top: 25px;
  width: 90%;
  margin: auto;
}

.loading-container {
  position: fixed;
  z-index: 11;
  width: 100%;
  height: 100%;
  z-index: 11;

  transition: all ease 1.4s;
}

.activities-quantities-container {
  flex-wrap: wrap;
}

/* LOADING--------------------------------------- */

.loading-container img {
  height: 100%;
  scale: 0.8;
}

/*SETTINGS---------------------------------------------------*/

.nav-settings-container {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
}

.nav-item-settings {
  display: flex;
  float: left;
  padding: 0px 15px 7px 15px;
  align-items: stretch;
  cursor: pointer;
}

.logo-frame {
  position: fixed;
  z-index: 20;
}

.logo-frame > img {
  width: 55vw;
  height: 55vw;
  position: absolute;
  bottom: 3vh;
  left: 18vw;
}

.switch-container {
  width: fit-content;
  display: block;
  margin-right: 40px;
  text-align: left;
}

.pop-container .switch-container,
.app-notification-container .switch-container {
  display: block;
  text-align: left;
  position: absolute;
  margin-bottom: 6px;
  right: 5%;
  bottom: 10%;
  margin-right: 0;
}
.app-switch-container {
  width: fit-content;
  display: block;
  text-align: center;
  margin-bottom: 6px;
}

.pop-col {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 60px;
}

.pop-label {
  margin-left: 5px;
  padding-bottom: 10px;
  font-size: 14px;
}
.app-label {
  margin-left: 5px;
  padding-bottom: 35px;
}

.notification-image-container {
  background: linear-gradient(to right, var(--color-400), var(--color-700));
  border: 0px solid;
  border-radius: 10px;
  align-items: center;
  padding: 40px 85px;
  margin-right: 30px;
}

.notification-image-container i {
  font-size: 60px;
  color: white;
}

.app-notification-container {
  width: 100%;
  margin-top: 20px;
  padding-top: 5px;
  position: relative;
  background-color: var(--color-bg-variant);
  display: flex;
  border-radius: 4px;
  gap: 10%;
}

.app-switch-container {
  width: fit-content;
  display: block;
  text-align: left;
  margin-bottom: 6px;
  position: absolute;
  right: 40px;
}

.fake-switch-unlabeled {
  width: 30px;
}

.fake-switch-unlabeled::after {
  width: 30%;
  height: 12px;
  top: 4px;
}

.checkbox-switch:checked + .fake-switch-unlabeled::after {
  left: 60%;
}

.pop-container {
  width: 370px;
  margin-top: 15px;
  padding: 10px 15px 0px 15px;
  align-items: center;
  position: relative;
  display: flex;
  border: 1px solid #e3e3e3;
  border-radius: 3.5px;
  background-color: var(--color-bg-variant);
}

.notification-card {
  margin-left: 100px;
  padding: 70px 60px;
  height: auto;
  margin-bottom: 0;
}

.settings-content {
  padding-left: 30px;
  padding-right: 50px;
  width: 100%;
  box-sizing: border-box;
}

.settings-password-container .form-btn-group {
  bottom: -80px;
  left: 80px;
}

.settings-register .form-title .form-subtitle {
  font-size: 16px;
  color: grey;
}

.settings-register .photo-load-container {
  position: static;
}

.settings-register .form-title {
  margin-top: 30px;
  font-size: 24px;
}

.scroll-view {
  height: 55%;
  overflow-y: auto;
  margin-top: 20px;
  padding: 20px 0 35px 0;
}

.scroll-view::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

.scroll-view::-webkit-scrollbar-thumb {
  background-color: #dcdcdc;
  border-radius: 10px;
}

.scroll-view::-webkit-scrollbar {
  width: 10px;
}

.settings-right-superior-container {
  position: absolute;
  right: 12px;
  top: 0;
  display: flex;
  justify-content: right;
}

.flex-container.data-settings-container {
  gap: 50px;
  justify-content: center;
  padding-bottom: 100px;
}

/* LOGS----------------------------------------------------------------------------------------- */

.log {
  text-align: start;
  display: flex;
  align-items: center;
  width: 60%;
  background-color: white;
  padding: 10px;
  margin: 2px;
  position: relative;
}

.log > td {
  padding: 5px;
  min-width: 20px;
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.main-logs {
  padding: 0 0 0 5%;
  width: 90%;

  overflow: hidden;
}
.vertical-line {
  margin-top: 10px;
  border-left: 2px solid black;
  height: 30px;
  position: relative;
  left: 50%;
}
.date-logs {
  margin-top: 5px;
}

.log-row-campo {
  color: #2084af;
  background-color: #d2e6ef;
  padding: 4px 10px 4px 0px;
  border-radius: 10px;
  width: 200px;
}

.table-log {
  table-layout: fixed;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--color-bg-variant);
  border-spacing: 10px 10px;
  border-radius: 10px;
  padding: 5px;
}

.low-row {
  margin-bottom: 10px;
}
.log-row-usuario {
  color: #2084af;
  width: 20px;
}
.log-row-icon {
  width: 20px;
}
.log-row-anterior {
  color: #7e7e7e;
  background-color: #f2d5d3;
  text-decoration: line-through;
  padding: 4px 10px 4px 4px;
  border-radius: 10px;
  width: 200px;
}
.log-row-nuevo {
  background-color: #d3f2d8;
  padding: 4px 10px 4px 4px;
  border-radius: 10px;
  width: 200px;
}

/*Preview*/

.preview header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.preview #projectScrollArea {
  max-height: 80vh;
}
.preview .btn-alternative {
  background-color: var(--color-bg-variant);
}

.preview .btn-primary {
  height: 40px;
}

.preview img {
  width: 10%;
}

/*MOVIL---------------------------------------------------------*/
@media (max-width: 575.98px) and (orientation: portrait) {
  .generator-options-container {
    overflow: auto;
    border-radius: 0;
  }

  .generator-inputs-container {
    margin: 0;
    width: 100%;
    border-radius: 0;
  }

  .generator-inputs-container .btn-primary {
    width: 100%;
    justify-content: center;
    font-size: 16px;
  }

  .generator-inputs-container .form-inputs-container > div {
    width: 100%;
  }

  .project-info-container {
    max-width: max-content;
  }

  .project-bottom-bar {
    padding: 10px 15px;
  }

  .project-bottom-bar .btn {
    width: 100%;
    justify-content: center;
    font-size: 16px;
  }

  .flex-container.generator {
    flex-direction: column-reverse;
  }

  .root-editor .submenu-overlay {
    transition: all ease 0.5s;
    position: absolute;
    height: auto;
    z-index: 2;
    top: 15px;
    left: 0;
    display: flex;
  }

  #principal-container.root-project {
    padding: 0 10px;
  }

  .editor-data-settings-container {
    max-width: 20rem;
    margin-left: 0;
    margin-right: 0;
    max-height: 400px;
    overflow-y: scroll;
  }

  .editor-data-settings {
    padding-bottom: 160px;
  }

  .editor-data-settings .form-btn-group {
    bottom: auto;
  }

  .editor-text-properties-container {
    order: 2;
    flex-wrap: nowrap;
  }

  .editor-basic-functions-container {
    order: -1;
  }

  .editor-container {
    left: 0;
    margin: 90px 20px;
    width: auto;
  }

  .edition-top-bar {
    flex-direction: row-reverse;
    justify-content: center;
    flex-wrap: wrap;
    padding: 5px 3px;
  }

  .edition-top-bar button,
  .edition-top-bar input,
  .edition-top-bar select {
    max-width: 100px;
    min-height: 50px;
  }

  .edition-bottom-bar {
    flex-wrap: wrap;
    height: 390px;
    padding: 10px;
    justify-content: center;
  }

  .edition-bottom-bar > div {
    width: 100%;
  }

  .editor-btn-save {
    order: 1;
  }

  .sub-menu-container.palette {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    min-width: 100%;
    justify-content: center;
  }

  .sub-menu-container.palette ul {
    display: flex;
    width: 100%;
    padding: 0;
    flex-wrap: nowrap;
    flex-direction: row;
    overflow-x: auto;
    gap: 45px;
    align-items: center;
  }

  .sub-menu-container.palette ul li button img {
    min-height: 100%;
    width: auto;
    max-width: 185px;
  }

  .sub-menu-container.palette > div {
    padding-bottom: 0;
    width: 100%;
  }

  .sub-menu-container.palette .btn-action {
    bottom: auto;
    margin-top: 20px;
  }

  .sub-menu-container.palette:not(.is-hidden) {
    transform: translate(0);
    padding: 5px;
  }

  .sub-menu-top-inputs {
    display: flex;
    justify-content: center;
    overflow: hidden;
  }

  .mobile-palette-container {
    width: 100%;
  }

  .mobile-palette-container ul {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow-x: scroll;
    padding: 0;
  }

  .mobile-palette-container ul li {
    background-color: var(--color-default);
    border-radius: 7px;
    margin: 0 5px;
    padding: 5px;
  }

  .home-page .home-carousel-link {
    position: relative;
    left: 0;
  }

  .error-page {
    justify-content: center;
  }

  .logo-frame > img {
    width: 100%;
    height: 80%;
    position: static;
  }

  .error-page img {
    max-width: 100%;
  }
  .login-container {
    flex-wrap: wrap;
  }

  .flex-container.data-settings-container {
    flex-direction: column;
  }

  .login-container .logo-container {
    position: relative;
    left: 0;
  }

  .login-container > div {
    display: none;
  }

  .container-col-60,
  .container-col-30,
  .container-col-40,
  .container-col-50,
  .container-col-75 {
    width: 100%;
    max-width: 100%;
  }

  .card {
    padding: 30px 20px;
    max-width: 90%;
  }

  .card.flex-container {
    flex-wrap: wrap;
  }

  .activities-quantities-container {
    flex-wrap: nowrap;
    justify-content: center;
  }

  .activities-quantities-container .container-blue-rounded {
    width: 90px;
    height: 90px;
    padding: 10px;
    flex-direction: column;
    margin: 10px;
  }

  .activities-quantities-container span {
    text-align: center;
  }

  .chart-options {
    position: static;
    margin-bottom: 15px;
  }

  .logo-container {
    display: flex;
    width: 130px;
    align-self: center;
    position: absolute;
    left: 20px;
  }

  #main.register {
    padding: 0 20px;
  }

  #navigation-tab {
    padding: 20px;
  }

  #navigation-tab .flex-container {
    flex-wrap: wrap;
  }

  .table-container {
    overflow-x: scroll;
  }

  .table {
    /* zoom:55%; */
    margin: auto;
    font-size: 30px;
  }

  .registers-btn-action {
    width: fit-content;
  }

  .dataTable-bottom {
    flex-direction: column;
  }

  .dataTable-bottom div {
    position: static;
  }

  .mosaic-group {
    padding: 20px 0;
  }

  .mosaic-container {
    width: 100%;
  }

  .mosaic-card {
    width: 100%;
  }

  .registers-input-search {
    order: -2;
    width: 65%;
  }

  .icon-btn-on-mobile {
    width: 42px;
    overflow: hidden;
  }

  #btn-change-view,
  #btn-filters {
    order: -1;
  }

  .bar-chart-box,
  .pie-chart-box,
  .donut-chart-box {
    width: 100%;
    height: 100%;
  }

  .form-title {
    min-height: 65px;
  }
  .form-steps-container {
    display: flex;
    position: relative;
    margin-top: 37px;
  }

  .form-steps-container .form-steps {
    display: flex;
    gap: 20%;
    justify-content: center;
    position: absolute;
    width: 100%;
  }

  .form-steps-container .form-meter .progress-bar {
    width: calc((100% / 3) * 2);
    height: 5px;
  }

  .form-steps-container .step-mobile {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .form-steps-container .form-meter {
    width: 100%;
    height: 5px;
  }

  .form-card {
    flex-direction: column-reverse;
    gap: 50px;
  }

  .form-group {
    width: 100%;
    margin-right: 0;
  }

  .form-btn-group {
    width: 100%;
    justify-content: center;
    position: static;
    padding-top: 60px;
  }

  .modal {
    margin: 15px;
  }

  .form-group input[type="text"],
  .form-group select {
    height: 25px;
    min-width: 100%;
    box-sizing: border-box;
    height: 40px;
  }

  .photo-load-container {
    position: relative;
    right: 0;
    flex-direction: column;
  }

  .vertical-menu .main-options ul,
  .vertical-menu .sub-menu-container ul {
    padding: 0;
  }

  .sub-menu-container ul,
  .sub-menu-container > button {
    position: relative;
    top: 12%;
  }

  .filters-container {
    top: 45%;
  }

  .context-actions-container {
    justify-content: space-between;
    padding: 20px;
    flex-wrap: wrap;
    position: static;
  }

  .nav-settings-container {
    overflow: visible;
  }

  .nav-settings-container.carousel-container .carousel {
    min-width: 265px;
    height: 100px;
    margin-bottom: 0;
    min-height: min-content;
  }
}

/*TABLET-------------------------------------------------------------------------------*/

@media (min-width: 576px) and (max-width: 991.98px) and (orientation: portrait) {
  .editor-container {
    left: 0;
    width: auto;
    margin: 90px auto;
  }

  .edition-top-bar {
    flex-wrap: wrap;
  }

  .editor-text-properties-container {
    order: 2;
    flex-wrap: nowrap;
  }

  .editor-basic-functions-container {
    order: 2;
  }

  .edition-top-bar button,
  .edition-top-bar input,
  .edition-top-bar select {
    max-width: 100px;
    min-height: 50px;
  }

  .root-editor .submenu-overlay {
    transition: all ease 0.5s;
    position: absolute;
    height: auto;
    z-index: 2;
    top: 15px;
    left: 0;
    display: flex;
  }

  .editor-data-settings-container {
    max-width: none;
    margin-left: 0;
    margin-right: 0;
    max-height: 400px;
    overflow-y: scroll;
  }

  .editor-data-settings {
    padding-bottom: 160px;
  }

  .editor-data-settings .form-btn-group {
    bottom: auto;
  }

  .edition-top-bar .form-group {
    width: fit-content;
  }

  .edition-bottom-bar button {
    font-size: 17px;
  }

  .edition-bottom-bar {
    flex-wrap: wrap;
    height: fit-content;
    padding: 10px;
    justify-content: center;
    align-items: center;
  }

  .flex-container.data-settings-container {
    flex-direction: column;
  }

  .range-container {
    justify-content: center;
  }

  .editor-btn-save {
    order: 1;
  }

  .sub-menu-container.palette {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    min-width: 100%;
    justify-content: center;
  }

  .sub-menu-container.palette ul {
    display: flex;
    width: 100%;
    padding: 0;
    flex-wrap: nowrap;
    flex-direction: row;
    overflow-x: auto;
    gap: 45px;
    align-items: center;
  }

  .sub-menu-container.palette ul li button img {
    min-height: 100%;
    width: auto;
    max-width: 185px;
  }

  .sub-menu-container.palette > div {
    padding-bottom: 0;
    width: 100%;
  }

  .sub-menu-container.palette:not(.is-hidden) {
    transform: translate(0);
    padding: 5px;
  }

  .sub-menu-top-inputs {
    display: flex;
    justify-content: center;
    overflow: hidden;
  }

  .mobile-palette-container {
    width: 100%;
  }

  .mobile-palette-container ul {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    overflow-x: scroll;
    padding: 0;
  }

  .mobile-palette-container ul li {
    background-color: var(--color-default);
    border-radius: 7px;
    margin: 0 5px;
    padding: 5px;
    min-width: 13vw;
  }

  .home-cover img {
    position: relative;
  }

  .mosaic-group {
    justify-content: center;
  }

  .mosaic-img-container {
    height: 95px;
  }

  .login-container {
    flex-wrap: wrap;
  }

  .login-container > div {
    display: none;
  }

  .login-container .logo-container {
    position: relative;
    left: 0;
  }

  .login-container #main {
    padding: 20% 0;
  }

  .form-group label,
  input {
    font-size: 18px;
  }

  .container-col-75 {
    width: 100%;
    max-width: 100%;
  }

  #main.register {
    padding: 0px 35px;
    justify-content: center;
  }

  .vertical-menu.is-closed,
  #fake-vertical-menu {
    display: none;
  }

  .table-container {
    overflow-x: scroll;
  }

  #navigation-tab .grey-text {
    display: none;
  }

  .form-card {
    flex-direction: column-reverse;
    gap: 50px;
    align-items: center;
  }

  .form-group {
    margin-right: 35px;
  }

  .form-flex,
  .form-title {
    padding: 15px;
  }

  .form-steps-container {
    padding: 20px;
  }

  .photo-load-container {
    position: relative;
    flex-direction: column;
    right: 0;
  }
}
