body {
    margin: 0;
    min-width: 100%;
    background-color: var(--color-bg);
    font-family: fuente-regular;
  }
  
  img {
    width: 100%;
    object-fit: cover;
  }
  
  li {
    list-style-type: none;
  }
  
  a {
    text-decoration: none;
    cursor: pointer;
    color: var(--color-text);
    font-size: 13px;
  }

  a:hover{
    color: var(--color-600);
  }

  input, select, textarea{
    background-color: var(--color-bg-input);
    color:var(--color-text);
  }


  input[type="range"] {
    height: 5px;
    width: 150px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 5px;
    background-image: linear-gradient(white, var(--color-700));
    background-size: 70% 100%;
    background-repeat: no-repeat;
  }

  input[type=range]:focus {
    outline: none;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    border-radius: 50%;
    background:  var(--color-700);
    cursor: ew-resize;
    box-shadow: 0 0 2px 0 rgb(89, 89, 89);
    transition: background .3s ease-in-out;
  }

  input[type="range"]::-moz-range-thumb {
    border-radius: 50%;
    background:  var(--color-700);
    cursor: ew-resize;
    box-shadow: 0 0 2px 0 rgb(89, 89, 89);
    transition: background .3s ease-in-out;
  }
  
  input[type="range"]::-ms-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background:  var(--color-700);
    cursor: ew-resize;
    box-shadow: 0 0 2px 0 rgb(89, 89, 89);;
    transition: background .3s ease-in-out;
  }

  input::placeholder{
    color:var(--color-gray)
  }
  
  span,p,h1,h2,h3,h4,h5,label{
    color:var(--color-text);
  }
  
  input:focus,select:focus{
    outline: 1px solid var(--color-gray);
  }

  table{
    min-width: 500px;
  }