.is-hidden {
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}
.is-visible {
  opacity: 1;
  visibility: visible;
  z-index: 11;
}
.is-selected {
  width: 100%;
}

.is-visible-on-mobile {
  display: none;
}
.is-visible-on-tablet {
  display: none;
}

/* Estados de elementos de manipulacion de los registros------------------------------------- */

.is-showing-options + .register-options-container {
  visibility: visible;
  opacity: 1;
  cursor: auto;
}

/* Estados de formulario------------------------------------------------------------------ */

.step.is-complete {
  color: var(--color-primary);
  border-bottom: 5px solid var(--color-primary);
}

.step.is-current::after {
  content: "";
  padding: 0px 40px;
  position: absolute;
  bottom: -5px;
  left: 0;
  border-bottom: 5px solid var(--color-primary);
}

.step-mobile.is-complete {
  background-color: var(--color-primary);
  color: var(--color-bg-variant);
}

.form-toast.is-visible {
  transform: translateX(0px);
  transition: all ease 0.5s;
}

/* Estados del menu lateral -------------------------------------------------------------- */

#fake-vertical-menu:not(.is-closed) {
  transition: width ease 2s;
  animation: openMenu 3.5s 0s 1;
  animation-fill-mode: forwards;
}

#fake-vertical-menu.is-closed {
  margin: 0;
  animation: closeMenu 2s 0s 1;
  animation-fill-mode: forwards;
  min-width: 90px;
}

.vertical-menu:not(.is-closed) {
  animation: openMenu 3.5s 0s 1;
  animation-fill-mode: forwards;
  transition: width ease 2s;
}
/* .vertical-menu:not(.is-closed) .options-container {
  animation: openMenu 3.5s 0s 1;
  animation-fill-mode: forwards;
  transition: width ease 2s;
} */
.vertical-menu:not(.is-closed) .main-options {
  animation: openMenu 3.5s 0s 1;
  animation-fill-mode: forwards;
  transition: width ease 2s;
}

.vertical-menu.is-closed {
  animation: closeMenu 2s 0s 1;
  animation-fill-mode: forwards;
}

.vertical-menu.is-closed .options-container {
  width: 90px;
  transition: all ease 1s;
  animation: closeMenu 2s 0s 1;
  animation-fill-mode: forwards;
}

.vertical-menu.is-closed .main-options {
  width: 90px;
  transition: all ease 1s;
  animation: closeMenu 2s 0s 1;
  animation-fill-mode: forwards;
}

.vertical-menu.is-closed ul li {
  width: auto;
  transition: all ease 1s;
}

.vertical-menu.is-closed ul li span {
  pointer-events: none;
}

.vertical-menu.is-closed .main-menu-button {
  justify-content: center;
  padding-right: 0;
  width: 45px;
  text-align: left;
  justify-content: left;
}

.sub-menu-container.is-hidden {
  transform: translate(-30px);
}

.sub-menu-button.is-selected {
  border-left: 2px solid var(--color-400);
}

@keyframes openMenu {
  0% {
    width: 90px;
  }
  35% {
    width: 240px;
  }
  100% {
    width: 220px;
    min-width: 220px;
  }
}

@keyframes closeMenu {
  0% {
    width: 220px;
  }
  50% {
    width: 90px;
  }
  100% {
    width: 90px;
  }
}

@media (max-width: 575.98px) and (orientation: portrait) {
  .vertical-menu.is-closed {
    display: none;
  }

  .vertical-menu:not(.is-closed) {
    animation:none;
  }

  #fake-vertical-menu:not(.is-closed) {
    animation: none;
  }
  

  .is-hidden-on-mobile {
    display: none;
  }

  #toggle-btn.is-hidden-on-mobile,
  #toggle-x-btn.is-hidden {
    transition: all 0.2s ease;
    transform: rotate(180deg);
  }

  #sub-principal-container.is-contracted {
    animation: none;
  }

  .sub-menu-container:not(.is-hidden) {
    transform: translate(200px);
  }

  .is-visible-on-mobile {
    display: block;
  }

  @keyframes openMenu {
    0% {
      width: 90px;
    }
    20% {
      width: 95%;
    }
    50% {
      width: 85%;
    }
    100% {
      width: 85%;
    }
  }

  @keyframes closeMenu {
    0% {
      width: 85%;
    }
    50% {
      width: 90px;
    }
    100% {
      width: 90px;
    }
  }
}

@media (min-width: 576px) and (max-width: 991.98px) and (orientation: portrait) {

  .is-visible-on-tablet{
    display: block;
  }
}

