
  
  #main {
    padding: 0 75px;
    width: auto;
  }


  /* Barra de navegación lateral-------------------------------------------------------------- */
  #fake-vertical-menu{
    height: 100%;
    opacity: 0;
  }

  
  .vertical-menu {
    position: fixed;
    top: 0px;
    z-index: 7;
  }

  #overlay-container{
    position: relative;
    height: 100%;
    width: 2px;
  }

  #submenu-overlay{
    transition: all ease 0.5s;
    position: absolute;
    width: 100vw;
    height: 100vw;
    z-index: -10;
    left: 15px;
    display: flex;
    align-items: center;
  }

  .vertical-menu .main-menu-button,
  .vertical-menu .sub-menu-button {
    padding: 15px 12px 15px 12px;
    box-sizing: border-box;
    align-items: center;
    width: 100%;
    display: flex;
    overflow: hidden;
    gap: 15px;
    text-align:left;
    transition: all ease 1s;
  }
  
  .vertical-menu .main-options ul,
  .vertical-menu .sub-menu-container ul {
    display: flex;
    flex-direction: column;
    gap: 22px;
    padding: 15px;
  }
  
  .vertical-menu .main-options {
    padding-left: 2px;
  }
  
  .vertical-menu .sub-menu-container {
    transition: all .5s ease;
    height: 100%;
    position: relative;
    right: 2px;
    bottom: 2px;
  }

  .vertical-menu .main-options ul li,
  .vertical-menu .sub-menu-container ul li {
    width: 185px;
    transition: width 1s;
  }

  .vertical-menu .logo-container {
    height: 50px;
    margin: 18px auto 0 auto;
  }

.vertical-menu .logo-container img{
    height: 100%;
    object-fit: cover;
    width: auto;
    cursor: pointer;
}
  
  .vertical-menu .options-container {
    display: flex;
    height: 100vh;
    width: 220px;
  }


/* Header---------------------------------------------------------------------------------------- */

#header {
    height: 70px;
    position: sticky;
    top: 0;
    z-index: 10;
  }
  
  #header-menu {
    position: absolute;
    right: 4%;
    height: 100%;
    top: 0;
  }
  
  #user-menu{
    position: relative;
  }
  
  
/* Navigation tab ------------------------------- */
  
#navigation-tab {
    padding: 23px 50px;
    position: sticky;
    top: 70px;
    z-index: 3;
  }

  
  
  #principal-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    transition: all 2s ease;
  }

  #principal-container.root-project{
    padding: 0 65px;
    box-sizing: border-box;
    display: block;
    position: relative;
  }

  #sub-principal-container {
    width: 100%;
    position: relative;
    transition: all 2s ease;
  }

  


  
/*MOVIL---------------------------------------------------------*/
@media (max-width: 575.98px) and (orientation: portrait) {

  #principal-container,#header{
    flex-direction: row-reverse;
  }

  #overlay-container{
    width: 100%;
    display: block;
  }

  #general-btn-add{
    position:fixed;
    bottom: 5%;
    right: 5%;
    z-index: 10;
    width: 50px;
    height: 50px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  }

  #header{
    padding: 20px;
    box-sizing: border-box;
  }

  .vertical-menu{
    width: 80% !important;
  }
  
   .vertical-menu .options-container{
    flex-direction: row-reverse;
   }

  .vertical-menu .main-options{
    width: 100%;
    padding-top: 95px;
  }

  #main{
    display: block;
    padding: 25px;
    box-sizing: border-box;
  }

  #toggle-btn,#toggle-x-btn{
position: absolute;
top: 20%;
right: 2%;
  }



}

/*TABLET-------------------------------------------------------------------------------*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: portrait) {
  .vertical-menu.is-closed, #fake-vertical-menu{
    display: none;
  }

  #toggle-btn{
    font-size: 30px;
    margin: 0 20px;
  }

  #overlay-container{
    width: 100%;
    display: block;
  }

  #navigation-tab .flex-container{
    justify-content: center;
  }

  .root-editor #toggle-btn{
    display: none;
  }
}
